import * as React from 'react'

import { spacing, styled } from '@thg-commerce/gravity-theme'
import { ItemMargin } from '@thg-commerce/gravity-theme/margin'

export interface BackgroundContainerProps {
  children: React.ReactNode
  backgroundColour?: string
  margin?: ItemMargin
}

const BackgroundContainer = styled.div<{
  backgroundColour?: string
  margin?: ItemMargin
}>`
  ${(props) =>
    props.backgroundColour &&
    `
      background-color: ${props.backgroundColour};
      padding: ${spacing(2)} 0;
      max-width: 100%;
  `}

  margin: ${(props) =>
    props.margin === 'marginBoth'
      ? `${spacing(2)} 0`
      : props.margin === 'marginTop'
      ? `${spacing(2)} 0 0 0`
      : props.margin === 'marginBottom'
      ? `0 0 ${spacing(2)}`
      : '0'}
`

export const WidgetBackgroundColour = (props: BackgroundContainerProps) => {
  return (
    <BackgroundContainer
      backgroundColour={props.backgroundColour}
      margin={props.margin}
    >
      {props.children}
    </BackgroundContainer>
  )
}
